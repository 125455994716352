import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from "../components/container";

const Cookies = () => {
  return (
    <Layout>
      <SEO
        title="Umowa powierzenia przetwarzania danych osobowych"
        description=""
      />
      <br />
      <br />
        <Container>
          <h2>Załącznik nr 1 do Regulaminu świaczenia usługi QuickCRM</h2>
          <br />
          <h1>Umowa powierzenia przetwarzania danych osobowych</h1>
      <div className="container container--text">
        <p>
        Umowa powierzenia danych osobowych reguluje zasady przetwarzania danych osobowych przez Podmiot przetwarzający na rzecz Administratora danych korzystającego z systemu QuickCRM.
        </p>
        <center>
        <p> <strong>§ 1< br/> Powierzenie przetwarzanych danych osobowych</strong></p> </center>
        <p>
        <ol>
          <li>
            Administrator danych powierza Podmiotowi przetwarzającemu, w trybie art. 28 ogólnego rozporządzenia o ochronie danych z dnia 27 kwietnia 2016 r. (zwanego w dalszej części „Rozporządzeniem”) dane osobowe do przetwarzania, na zasadach  i w celu określonym w niniejszej Umowie.</li>
          <li>Podmiot przetwarzający zobowiązuje się przetwarzać powierzone mu dane osobowe zgodnie z niniejszą umową, Rozporządzeniem oraz z innymi przepisami prawa powszechnie obowiązującego, które chronią prawa osób, których dane dotyczą.
</li>
<li>
Podmiot przetwarzający oświadcza, iż stosuje środki bezpieczeństwa spełniające wymogi Rozporządzenia. </li>
        </ol>
        </p>
         <center>
        
        <p> <strong>§ 2< br/> Zakres i cel przetwarzania danych</strong></p>
        
        </center>
        <p>
<ol>
<li>Podmiot przetwarzający będzie przetwarzał, powierzone na podstawie umowy dane  w których mogą występować między innymi imiona i nazwiska osób, adresy e-mail, numery telefonów oraz inne wprowadzane przez użytkowników systemu. 
</li>
<li>Powierzone przez Administratora danych dane osobowe będą przetwarzane przez Podmiot przetwarzający wyłącznie w celu  dostarczania usług zgodnie z postanowieniami odrębnej umowy wsparcia systemu.</li>
</ol>
        </p>
          <center>
        
        <p> <strong>§ 3< br/>Obowiązki podmiotu przetwarzającego </strong></p>
        </center>
<p>
<ol>
<li>Podmiot przetwarzający zobowiązuje się, przy przetwarzaniu powierzonych danych osobowych, do ich zabezpieczenia poprzez stosowanie odpowiednich środków technicznych i organizacyjnych zapewniających adekwatny stopień bezpieczeństwa odpowiadający ryzyku związanym z przetwarzaniem danych osobowych, o których mowa w art. 32 Rozporządzenia.
</li>
<li>Podmiot przetwarzający zobowiązuje się dołożyć należytej staranności przy przetwarzaniu powierzonych danych osobowych.</li>
<li>Podmiot przetwarzający zobowiązuje się do nadania upoważnień do przetwarzania danych osobowych wszystkim osobom, które będą przetwarzały powierzone dane w celu realizacji niniejszej umowy.  </li>
<li>Podmiot przetwarzający zobowiązuje się zapewnić zachowanie w tajemnicy,  (o której mowa w art. 28 ust 3 pkt b Rozporządzenia) przetwarzanych danych przez osoby, które upoważnia do przetwarzania danych osobowych w celu realizacji niniejszej umowy, zarówno w trakcie zatrudnienia ich w Podmiocie przetwarzającym, jak i po jego ustaniu.</li>
<li>Podmiot przetwarzający po zakończeniu świadczenia usług związanych  z przetwarzaniem danych, usuwa wszelkie ich istniejące kopie, chyba że prawo Unii lub prawo państwa członkowskiego nakazują przechowywanie danych osobowych.</li>
<li>W miarę możliwości Podmiot przetwarzający pomaga Administratorowi  w niezbędnym zakresie wywiązywać się z obowiązku odpowiadania na żądania osoby, której dane dotyczą oraz wywiązywania się z obowiązków określonych w art. 32-36 Rozporządzenia. </li>
<li>Podmiot przetwarzający po stwierdzeniu naruszenia ochrony danych osobowych bez zbędnej zwłoki zgłasza je administratorowi w ciągu 48h. </li>
</ol>
</p>
    <center>
        
        <p> <strong>§ 4< br/>Prawo kontroli </strong></p>
        </center>
        <p>
        <ol>
        <li>Administrator danych zgodnie z art. 28 ust. 3 pkt h) Rozporządzenia ma prawo kontroli, czy środki zastosowane przez Podmiot przetwarzający przy przetwarzaniu i zabezpieczeniu powierzonych danych osobowych spełniają postanowienia umowy. </li>
        <li>Administrator danych realizować będzie prawo kontroli w godzinach pracy Podmiotu przetwarzającego i z minimum 7 dniowym jego uprzedzeniem.</li>
        <li>Podmiot przetwarzający zobowiązuje się do usunięcia uchybień stwierdzonych podczas kontroli w terminie wskazanym przez Administratora danych nie dłuższym niż 7 dni.</li>

        <li>Podmiot przetwarzający udostępnia Administratorowi wszelkie informacje niezbędne do wykazania spełnienia obowiązków określonych w art. 28 Rozporządzenia. </li>
       </ol>
     </p>
        <center>
        
        <p> <strong>§ 5< br/>Dalsze powierzenie danych do przetwarzania</strong></p>
        </center>
        <p>
        <ol>
        <li>Podmiot przetwarzający może powierzyć dane osobowe objęte niniejszą umową do dalszego przetwarzania podwykonawcom jedynie w celu wykonania umowy. </li>
        <li>Przekazanie powierzonych danych do państwa trzeciego może nastąpić jedynie na pisemne polecenie Administratora danych chyba, że obowiązek taki nakłada na Podmiot przetwarzający prawo Unii lub prawo państwa członkowskiego, któremu podlega Podmiot przetwarzający. W takim przypadku przed rozpoczęciem przetwarzania Podmiot przetwarzający informuje Administratora danych o tym obowiązku prawnym, o ile prawo to nie zabrania udzielania takiej informacji  z uwagi na ważny interes publiczny.</li>
       <li>Podwykonawca, o którym mowa w §3 ust. 2 Umowy winien spełniać te same gwarancje i obowiązki jakie zostały nałożone na Podmiot przetwarzający  w niniejszej Umowie. </li>
       <li>Podmiot przetwarzający ponosi pełną odpowiedzialność wobec Administratora za nie wywiązanie się ze spoczywających na podwykonawcy obowiązków ochrony danych.
       </li> 
       </ol>
        </p>
         <center>
        
        <p> <strong>§ 6 <br/>Odpowiedzialność Podmiotu przetwarzającego </strong></p>
        </center>
        <p>
        <ol><li>Podmiot przetwarzający jest odpowiedzialny za udostępnienie lub wykorzystanie danych osobowych niezgodnie z treścią umowy, a w szczególności za udostępnienie powierzonych do przetwarzania danych osobowych osobom nieupoważnionym. </li>
        <li>Podmiot przetwarzający zobowiązuje się do niezwłocznego poinformowania Administratora danych o jakimkolwiek postępowaniu, w szczególności administracyjnym lub sądowym, dotyczącym przetwarzania przez Podmiot przetwarzający danych osobowych określonych w umowie, o jakiejkolwiek decyzji administracyjnej lub orzeczeniu dotyczącym przetwarzania tych danych, skierowanych do Podmiotu przetwarzającego, a także o wszelkich planowanych,  o ile są wiadome, lub realizowanych kontrolach i inspekcjach dotyczących przetwarzania w Podmiocie przetwarzającym tych danych osobowych,  w szczególności prowadzonych przez inspektorów upoważnionych przez Generalnego Inspektora Ochrony Danych Osobowych. Niniejszy ustęp dotyczy wyłącznie danych osobowych powierzonych przez Administratora danych. 
</li>
        </ol>
        </p>
         <center>
        
        <p> <strong>§ 7 <br/>Czas obowiązywania umowy</strong></p>
        </center>
        <p>
        <ol>
        <li>Umowa powierzenia przetwarzania danych, o której mowa w niniejszym Dziale, zawierana jest na czas określony, powiązany z czasem świadczenia Usług wykupionych przez Usługobiorcę. Rozwiązanie umowy świadczenia drogą elektroniczną Usług udostępnianych w ramach Systemu jest jednoznaczne z rozwiązaniem umowy powierzenia przetwarzania danych.</li>

        </ol>
        </p>
         <center>
        
        <p> <strong>§ 8 <br/>Rozwiązanie umowy</strong></p>
        </center>
<p>
<ol>
<li>Administrator danych może rozwiązać niniejszą umowę ze skutkiem natychmiastowym gdy Podmiot przetwarzający:
</li>
</ol>
</p><p>a) pomimo zobowiązania go do usunięcia uchybień stwierdzonych podczas kontroli nie usunie ich w wyznaczonym terminie;<br />
b) przetwarza dane osobowe w sposób niezgodny z umową; <br />
c) powierzył przetwarzanie danych osobowych innemu podmiotowi bez zgody Administratora danych;</p>
 <center>
        
        <p> <strong>§ 9 <br/>Zasady zachowania poufności</strong></p>
        </center>
        <p><ol><li>Podmiot przetwarzający zobowiązuje się do zachowania w tajemnicy wszelkich informacji, danych, materiałów, dokumentów i danych osobowych otrzymanych od Administratora danych i od współpracujących z nim osób oraz danych uzyskanych w jakikolwiek inny sposób, zamierzony czy przypadkowy w formie ustnej, pisemnej lub elektronicznej („dane poufne”).
</li>
<li>Podmiot przetwarzający oświadcza, że w związku ze zobowiązaniem do zachowania w tajemnicy danych poufnych nie będą one wykorzystywane, ujawniane ani udostępniane bez pisemnej zgody Administratora danych w innym celu niż wykonanie Umowy, chyba że konieczność ujawnienia posiadanych informacji wynika  z obowiązujących przepisów prawa lub Umowy. </li>
</ol></p>
 <center>
        
        <p> <strong>§ 9 <br/>Postanowienia końcowe</strong></p>
      
        </center>
        <p>
        <ol><li>Umowa została sporządzona w dwóch jednobrzmiących egzemplarzach dla każdej ze stron.</li>
        <li>W sprawach nieuregulowanych zastosowanie będą miały przepisy Kodeksu cywilnego oraz Rozporządzenia.
</li>
<li>Sądem właściwym dla rozpatrzenia sporów wynikających z niniejszej umowy będzie sąd właściwy Administratora danych.</li>
</ol>
</p>
      </div>
      </Container>
    </Layout>
  );
};

export default Cookies;
